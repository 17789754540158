@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

/* base */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: #1f242d;
  color: #fff;
}

/* home */

.home {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 10% 0;
}

.home-content {
  max-width: 600px;
}

.home-content h3 {
  font-size: 32px;
  font-weight: 700;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: .7s;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 30px;
  animation: slideTop 1s ease forwards;
  animation-delay: .7s;
}

.home-content h3 span {
  color: #0ef;
}

.home-content h1 {
  font-size: 56px;
  font-weight: 700;
  margin: -3px 0;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1s;
}

.home-content p {
  font-size: 16px;
}

.home-img img {
  max-width: 450px;
  margin-right: -20px;
  opacity: 0;
  animation: zoomIn 1s ease forwards, floatImage 4s ease-in-out infinite;
  animation-delay: 1s, 3s;
}

.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid #0ef;
  border-radius: 50%;
  font-size: 20px;
  color: #0ef;
  text-decoration: none;
  margin: 30px 15px 30px 0;
  transition: .5s ease;
  opacity: 0;
  animation: slideTop 1s ease forwards;
  animation-delay: .7s;
}

.social-media a:hover {
  background: #0ef;
  color: #1f242d;
  box-shadow: 0 0 20px #0ef;
}

.btn {
  display: inline-block;
  padding: 12px 28px;
  background: #0ef;
  border-radius: 40px;
  box-shadow: 0 0 10px #0ef;
  font-size: 16px;
  color: #1f242d;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: .7s;
}

/* about */

.about {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 70px 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: #323741;
}

.about-content h2 span {
  color: #0ef;
}

.about-img img {
  width: 35vw;
  max-width: 450px;
  margin-right: 30px;
  opacity: 0;
  animation: zoomIn 1s ease forwards;
}

.heading {
  text-align: center;
  font-size: 4.5rem;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1s;
}

.about-content h2 {
  text-align: left;
  line-height: 1.2;
}

.about-content h3 {
  font-size: 2.6rem;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1s;
}

.about-content p {
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}

/* services */

.services {
  padding: 80px 50px 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.services h2 {
  margin-bottom: 3.5rem;
}

.services h2 span {
  color: #0ef
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.services-container .services-box {
  flex: 1 1 30rem;
  background: #2d323b;
  padding: 3rem 2rem 4rem;
  border-radius: 2rem;
  text-align: center;
  border: .2rem solid #252930;
  transition: .5s ease;
}

.services-container .services-box:hover {
  border-color: #0ef;
  transform: scale(1.02);
}

.services-box i {
  font-size: 7rem;
  color: #bacdf3;
  opacity: 0;
  animation: zoomIn 1s ease forwards;
}

.services-box h3 {
  font-size: 1.7rem;
  margin: 1rem 0 1.4rem;
  opacity: 0;
  animation: slideTop 1s ease forwards;
  animation-delay: 1s;
}

.services-box p {
  font-size: 1rem;
  margin: 1rem 0 3rem;
}
/* portfolio */

.portfolio {
  padding: 80px 50px 0;
  position: relative;
  width: 100%;
  height: 100vh;
  background: #323741;
}

.portfolio h2 {
  margin-bottom: 3.5rem;
}

.portfolio h2 span {
  color: #0ef
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2.5rem;
}

.portfolio-container .portfolio-box {
  position: relative;
  border-radius: 2rem;
  box-shadow: 0 0 1rem #323741;
  overflow: hidden;
  display: flex;
}

.portfolio-box img {
  width: 100%;
  transition: .5s ease;
}

.portfolio-box:hover img {
  transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.404));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  transform: translateY(100%);
  transition: .3s ease;
}

.portfolio-box:hover .portfolio-layer {
  transform: translateY(0);
}

.portfolio-layer h4 {
  font-size: 1.7rem;
}

.portfolio-layer p {
  font-size: 1rem;
  margin: .3rem 0 1rem;
}

.portfolio-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: #ffffff;
  border-radius: 50%;
}

.portfolio-layer a i {
  font-size: 2rem;
  color: #252930;
}

/* contact */

.contact {
  padding: 80px 50px 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.contact h2 {
  margin-bottom: 3rem;
}

.contact h2 span {
  color: #0ef
}

.contact form {
  max-width: 50rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.contact .form-base {
  padding: -20px 50px 0;
  position: relative;
  width: 80%;
  height: 80%;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  height: 50%;
  padding: .7rem;
  font-size: 1.6rem;
  color: #dcdcdc;
  background-color: #323741;
  border-radius: .8rem;
  margin: .7rem 0;
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
}

.contact form .btn {
  margin-top: 2rem;
  cursor: pointer;
}

/* footer */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background: #323741;
}

.footer-text p {
  font-size: 1rem;
}

.footer-iconTop .up-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .8rem;
  background: #0ef;
  border-radius: .8rem;
  transition: .5s ease;
}

.footer-iconTop .up-btn:hover {
  box-shadow: 0 0 1rem #0ef;
}

.footer-iconTop .up-btn i {
  font-size: 2.4rem;
  color: #1f242d;
}

/* KEYFRAMES ANIMATION */
@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatImage {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-18px);
  }
  100% {
    transform: translateY(0);
  }
}


/* BREAKPOINTS */

@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media (min-width: 991px) {
  section {
    padding: 10rem 3% 2rem;
  }

  .footer {
    padding: 2rem 3%;
  }
}
