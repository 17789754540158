@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  cursor: default;
  opacity: 0;
  animation: slideRight 1s ease forwards;
}

.navbar a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  margin-left: 35px;
  transition: .3s;
  opacity: 0;
  animation: slideTop .5s ease forwards;
}

.navbar a:hover {
  color: #0ef;
}

  @keyframes slideTop {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }
}
